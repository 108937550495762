import { useUserSession } from './useUserSession';
import { useCountryStatesQuery } from '@src/queries/CountryStatesQuery.generated';
export var useStateName = function useStateName(country, stateCode) {
  var _userSession$language, _userSession$country, _countryStates$getCou;

  var _useUserSession = useUserSession(),
      userSession = _useUserSession.userSession;

  var userLanguage = (userSession === null || userSession === void 0 ? void 0 : (_userSession$language = userSession.language) === null || _userSession$language === void 0 ? void 0 : _userSession$language.toLowerCase()) || '';
  var userCountry = (userSession === null || userSession === void 0 ? void 0 : (_userSession$country = userSession.country) === null || _userSession$country === void 0 ? void 0 : _userSession$country.toUpperCase()) || '';

  var _useCountryStatesQuer = useCountryStatesQuery({
    variables: {
      country: country || '',
      locale: userLanguage === 'en' ? 'en_US' : "".concat(userLanguage, "_").concat(userCountry)
    }
  }),
      countryStates = _useCountryStatesQuer.data;

  if (!country || !stateCode) {
    return null;
  }

  var selectedState = countryStates === null || countryStates === void 0 ? void 0 : (_countryStates$getCou = countryStates.getCountryStates) === null || _countryStates$getCou === void 0 ? void 0 : _countryStates$getCou.find(function (state) {
    return (state === null || state === void 0 ? void 0 : state.stateCode) === stateCode;
  });
  return selectedState === null || selectedState === void 0 ? void 0 : selectedState.stateName;
};