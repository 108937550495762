/**
 * The search was architected to always have a "term", late in the game
 * it was revealed that we need to support termless searches, filtered by
 * facet only. Our stop gap solution, in lieu of rearchecting search, is to
 * use a special string (`facet-search`) as the value for the `term` parameter.
 *
 * A variety of components rely on the following value to conditionally hide
 * certain markup and replace the `facet-seach` string with an empty string.
 */
export var FACET_SEARCH = 'facet-search';
export var isTermlessSearch = function isTermlessSearch(router) {
  return router.asPath.includes(FACET_SEARCH);
};